import { api } from '..';
import { OFFER_RESOURCE, FRAUD_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = (idOffer, idFraud) => ({
  [OFFER_RESOURCE]: idOffer,
  [FRAUD_RESOURCE]: idFraud,
});

/**
 *
 * @param {Offer} offer
 * @param {Fraud} fraud
 * @returns {Promise.<string>}
 */
export async function updateFraud(offer, fraud) {
  const partialUrl = api.createUrl(getResources(offer.id, fraud.id));
  const {
    data: { offerFraud },
  } = await api.update(partialUrl, fraud.payload());
  return new ResponseBuilder(offerFraud.id);
}
