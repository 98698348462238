import { api } from '..';
import { OFFER_RESOURCE, FRAUD_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [FRAUD_RESOURCE]: undefined,
});

/**
 *
 * @param {Offer} offer
 * @param {Fraud} newFraud
 * @returns {ResponseBuilder.<String>}
 */
export async function addFraud(offer, newFraud) {
  const partialUrl = api.createUrl(getResources(offer.id));
  const {
    data: { offerFraud },
  } = await api.create(partialUrl, newFraud.payload());
  return new ResponseBuilder(offerFraud.id);
}
