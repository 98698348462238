<template>
  <div>
    <div v-if="fraud" class="flex w-full h-10 mb-6">
      <div
        class="w-full text-sm flex items-center justify-center border cursor-pointer rounded-l"
        :class="[templateSelected ? 'bg-orange-400 hover:bg-orange-300' : 'bg-gray-300 hover:bg-gray-400']"
      >
        <asterix-dropdown
          :options="templatesAvailables"
          option-list-position="bottom"
          class="w-full h-full"
          @change="onChangeTemplate"
        >
          <template #icon>
            <div class="flex h-full items-center justify-end p-1 font-bold">
              <p v-if="isTemplateSelected">
                Selected template: <span>{{ templateSelected?.name }}</span>
              </p>

              <p v-else>Select template</p>
            </div>
          </template>
        </asterix-dropdown>
      </div>
      <div
        class="w-full font-bold text-sm flex items-center justify-center p-4 border cursor-pointer rounded-r"
        :class="[!templateSelected ? 'bg-orange-400 hover:bg-orange-300' : 'bg-gray-300 hover:bg-gray-400']"
        @click="onChangeTemplate(null)"
      >
        Customize
      </div>
    </div>
    <sun-form v-if="fraud" @submit="submitForm" @keypress.enter="submitForm">
      <ip-control :base-fraud="fraud" :is-disabled="isTemplateSelected" @update="onFraudUpdate" />
      <app-control :base-fraud="fraud" :is-disabled="isTemplateSelected" class="mt-6" @update="onFraudUpdate" />
      <anomalous-distribution
        :base-fraud="fraud"
        :is-disabled="isTemplateSelected"
        class="mt-6"
        @update="onFraudUpdate"
      />
      <referer :base-fraud="fraud" :is-disabled="isTemplateSelected" class="mt-6" @update="onFraudUpdate" />
      <div class="flex mt-12">
        <save-button
          id="fraud-submit"
          :loading="isLoading || isSaving"
          :disabled="fraudFormErrors"
          :text="isDuplicate ? 'Duplicate' : 'Save'"
        />
      </div>
    </sun-form>
    <div v-else>
      <card-form-loading :rows="3" />
      <card-form-loading :rows="2" class="mt-6" />
      <card-form-loading :rows="2" class="mt-6" />
      <card-form-loading :rows="2" class="mt-6" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ERRORS } from '@/i18n/forms/errors';
import Offer from '@/entities/ecommerce/Offer';
import Fraud from '@/entities/ecommerce/fraud/Fraud';
import SaveButton from '@/components/atoms/SaveButton';
import { addFraud } from '@/services/modules/ecommerce/offer/fraud/addFraud';
import { updateFraud } from '@/services/modules/ecommerce/offer/fraud/updateFraud';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import { deepClone } from '@/utils/deepClone';
import IpControl from '@/components/molecules/modules/ecommerce/fraud/IpControl';
import AppControl from '@/components/molecules/modules/ecommerce/fraud/AppControl';
import AnomalousDistribution from '@/components/molecules/modules/ecommerce/fraud/AnomalousDistribution';
import Referer from '@/components/molecules/modules/ecommerce/fraud/Referer';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import { updateOffer } from '@/services/modules/ecommerce/offer';

export default {
  name: 'FraudForm',
  components: {
    IpControl,
    AppControl,
    AnomalousDistribution,
    Referer,
    SaveButton,
    CardFormLoading,
    AsterixDropdown,
  },
  props: {
    baseOffer: {
      type: Offer,
      default: () => null,
    },
    viewMode: {
      type: String,
      default: () => null,
    },
    updatedOffer: {
      type: Object,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    fraudTemplates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSaving: false,
      i18nErrors: ERRORS.en,
      formErrors: {
        ips: null,
      },
      offer: null,
      fraud: null,
      templateSelected: null,
    };
  },
  computed: {
    isNewFraud() {
      return !this.baseOffer?.offerFraud?.id;
    },
    isDuplicate() {
      return this.viewMode === 'duplicate';
    },
    fraudFormErrors() {
      if (!this.fraud) return false;
      const proxyUsageType = !this.fraud.proxyUsageType.leadClick || !this.fraud.proxyUsageType.leadConversion;
      const usageType =
        !this.fraud.usageType.click || !this.fraud.usageType.lead_click || !this.fraud.usageType.lead_conversion;
      const frecuencyCapError = this.fraud.uniqueUserDailyCapping === null;

      return frecuencyCapError || usageType || proxyUsageType;
    },
    isTemplateSelected() {
      return !!this.templateSelected;
    },
    templatesAvailables() {
      return this.fraudTemplates.filter(template => template.name !== 'Custom');
    },
  },
  watch: {
    baseOffer() {
      this.loadOffer();
      this.setDefaultFraudTemplate();
    },
    updatedOffer(value) {
      this.offer = value;
      this.fraud = value?.offerFraud?.clone?.() || new Fraud();
    },
    fraud: {
      deep: true,
      handler(newFraud) {
        this.offer.offerFraud = newFraud;
        this.$emit('update-offer', this.offer);
      },
    },
  },
  created() {
    this.loadOffer();
    if (this.offer?.offerFraud) {
      this.fraud = this.offer?.offerFraud?.clone?.() || new Fraud();
    }
    this.setDefaultFraudTemplate();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    loadOffer() {
      this.offer = deepClone(this.updatedOffer);
    },
    setDefaultFraudTemplate() {
      if (!this.offer) return null;
      if (this.offer.fraudTemplate.name === 'Custom') return null;
      this.templateSelected = deepClone(this.offer.fraudTemplate);
    },
    onFraudUpdate(updatedFraud) {
      this.fraud = updatedFraud;
    },
    async submitForm(value) {
      if (!value.valid || this.fraudFormErrors) {
        return;
      }
      if (this.isDuplicate) {
        this.$emit('duplicate', this.offer);
      } else if (this.isNewFraud) {
        await this.createFraud();
        this.$emit('reload');
      } else {
        await this.updateFraud();
        this.$emit('reload');
      }
    },
    async createFraud() {
      try {
        this.isSaving = true;
        const { data: id } = await addFraud(this.baseOffer, this.fraud);
        if (this.isTemplateSelected) {
          await updateOffer(this.offer);
        }
        this.fraud.id = id;
        this.offer.offerFraud = this.fraud;
        this.createToast(Toast.success('Fraud created'));
      } catch (e) {
        this.createToast(Toast.error('Fraud not created', e.message));
        throw e;
      }
      this.isSaving = false;
    },
    async updateFraud() {
      try {
        this.isSaving = true;
        await updateFraud(this.baseOffer, this.fraud);
        if (this.isTemplateSelected) {
          await updateOffer(this.offer);
        }
        this.offer.offerFraud = this.fraud;
        this.createToast(Toast.success('Fraud updated'));
      } catch (e) {
        this.createToast(Toast.error('Fraud not updated', e.message));
        throw e;
      }
      this.isSaving = false;
    },
    onChangeTemplate(fraudTemplate) {
      if (!fraudTemplate) {
        this.templateSelected = null;
        this.fraud.templateId = null;
      } else {
        const template = deepClone(fraudTemplate);
        this.templateSelected = template;
        this.fraud.asn = template.asn;
        this.fraud.controlApps = template.controlApps;
        this.fraud.controlLanguage = template.controlLanguage;
        this.fraud.controlUserAgents = template.controlUserAgents;
        this.fraud.ipControl = template.ipControl;
        this.fraud.ipMissMatch = template.ipMissMatch;
        this.fraud.proxyUsageType = template.proxyUsageType;
        this.fraud.referer = template.referer;
        this.fraud.usageTYpe = template.usageType;
        this.fraud.templateId = template.id;
        this.offer.uniqueConversion = this.templateSelected.uniqueConversion;
        this.offer.uniqueUserDailyCapping = this.templateSelected.uniqueUserDailyCapping;
        this.offer.offerAttribution = this.templateSelected.offerAttribution;
        this.offer.fraudTemplate = template;
      }
    },
  },
};
</script>

<style scoped>
.group-description {
  @apply ml-2 pt-1 text-sm italic;
}
.card-subtitle-r {
  @apply rounded-tr-lg;
}
.card-subtitle-l {
  @apply rounded-tl-lg;
}
.card-subtitle {
  @apply bg-gray-200 px-6 border-b border-gray-400 flex items-center font-bold text-sm;
  height: 4rem;
}
.card-content {
  @apply flex flex-col p-6;
  height: calc(100% - 4rem);
}
</style>
