var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fraud
        ? _c("div", { staticClass: "flex w-full h-10 mb-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "w-full text-sm flex items-center justify-center border cursor-pointer rounded-l",
                class: [
                  _vm.templateSelected
                    ? "bg-orange-400 hover:bg-orange-300"
                    : "bg-gray-300 hover:bg-gray-400",
                ],
              },
              [
                _c("asterix-dropdown", {
                  staticClass: "w-full h-full",
                  attrs: {
                    options: _vm.templatesAvailables,
                    "option-list-position": "bottom",
                  },
                  on: { change: _vm.onChangeTemplate },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex h-full items-center justify-end p-1 font-bold",
                              },
                              [
                                _vm.isTemplateSelected
                                  ? _c("p", [
                                      _vm._v(" Selected template: "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.templateSelected?.name)
                                        ),
                                      ]),
                                    ])
                                  : _c("p", [_vm._v("Select template")]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1429464591
                  ),
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "w-full font-bold text-sm flex items-center justify-center p-4 border cursor-pointer rounded-r",
                class: [
                  !_vm.templateSelected
                    ? "bg-orange-400 hover:bg-orange-300"
                    : "bg-gray-300 hover:bg-gray-400",
                ],
                on: {
                  click: function ($event) {
                    return _vm.onChangeTemplate(null)
                  },
                },
              },
              [_vm._v(" Customize ")]
            ),
          ])
        : _vm._e(),
      _vm.fraud
        ? _c(
            "sun-form",
            {
              on: {
                submit: _vm.submitForm,
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [
              _c("ip-control", {
                attrs: {
                  "base-fraud": _vm.fraud,
                  "is-disabled": _vm.isTemplateSelected,
                },
                on: { update: _vm.onFraudUpdate },
              }),
              _c("app-control", {
                staticClass: "mt-6",
                attrs: {
                  "base-fraud": _vm.fraud,
                  "is-disabled": _vm.isTemplateSelected,
                },
                on: { update: _vm.onFraudUpdate },
              }),
              _c("anomalous-distribution", {
                staticClass: "mt-6",
                attrs: {
                  "base-fraud": _vm.fraud,
                  "is-disabled": _vm.isTemplateSelected,
                },
                on: { update: _vm.onFraudUpdate },
              }),
              _c("referer", {
                staticClass: "mt-6",
                attrs: {
                  "base-fraud": _vm.fraud,
                  "is-disabled": _vm.isTemplateSelected,
                },
                on: { update: _vm.onFraudUpdate },
              }),
              _c(
                "div",
                { staticClass: "flex mt-12" },
                [
                  _c("save-button", {
                    attrs: {
                      id: "fraud-submit",
                      loading: _vm.isLoading || _vm.isSaving,
                      disabled: _vm.fraudFormErrors,
                      text: _vm.isDuplicate ? "Duplicate" : "Save",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("card-form-loading", { attrs: { rows: 3 } }),
              _c("card-form-loading", {
                staticClass: "mt-6",
                attrs: { rows: 2 },
              }),
              _c("card-form-loading", {
                staticClass: "mt-6",
                attrs: { rows: 2 },
              }),
              _c("card-form-loading", {
                staticClass: "mt-6",
                attrs: { rows: 2 },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }